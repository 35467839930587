const routes = [
  // 灯谜
  {
    path: "/futureService/lanternRiddles",
    name: "lanternRiddles",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/lanternRiddles/index.vue"], resolve),
  },
  // 积分获取规则
  {
    path: "/futureService/pointsRule",
    name: "pointsRule",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/points/pointsRule.vue"], resolve),
  },
  // 非明珠社区积分获取规则
  {
    path: "/futureService/communityPointsRule",
    name: "communityPointsRule",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/points/communityPointsRule.vue",
      ], resolve),
  },
  // 积分页面
  {
    path: "/futureService/points",
    name: "points",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/points/index"], resolve),
  },
  //积分记录
  {
    path: "/futureService/pointsList",
    name: "pointsList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/points/pointsList"], resolve),
  },
  //积分排行榜
  {
    path: "/futureService/pointRankList",
    name: "pointRankList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/points/pointRankList"], resolve),
  },
  // 志愿积分
  {
    path: "/futureService/pointVolunteerList",
    name: "pointVolunteerList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/points/pointVolunteerList"], resolve),
  },
  //获取积分
  {
    path: "/futureService/getPoints",
    name: "getPoints",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/points/getPoints"], resolve),
  },
  // 时间银行签到结果
  {
    path: "/futureService/volunteerPointQRCode",
    name: "volunteerPointQRCode",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/points/volunteerPointQRCode"], resolve),
  },

  //签到
  {
    path: "/futureService/daySign",
    name: "daySign",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/daySign/index"], resolve),
  },

  // 商城
  {
    path: "/futureService/mall",
    name: "mall",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/mall/index"], resolve),
  },
  //商城详情
  {
    path: "/futureService/mallDetail",
    name: "mallDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/mall/mallDetail"], resolve),
  },
  //兑换成功
  {
    path: "/futureService/mallFinished",
    name: "mallFinished",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/mall/mallFinished"], resolve),
  },
  //订单详情
  {
    path: "/futureService/mallOrderDetail",
    name: "mallOrderDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/mall/mallOrderDetail"], resolve),
  },
  //订单列表
  {
    path: "/futureService/mallOrderList",
    name: "mallOrderList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/mall/mallOrderList"], resolve),
  },
  // 省级应用-积分商城------start
  {
    path: "/futureService/integrateMall",
    name: "integrateMall",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/integrateMall/index"], resolve),
  },
  //商城详情
  {
    path: "/futureService/shopDetail",
    name: "shopDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/integrateMall/shopDetail"], resolve),
  },
  //兑换成功
  {
    path: "/futureService/exchangeFinished",
    name: "exchangeFinished",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/integrateMall/exchangeFinished",
      ], resolve),
  },
  //订单详情
  {
    path: "/futureService/shopOrderDetail",
    name: "shopOrderDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/integrateMall/shopOrderDetail"], resolve),
  },
  //订单列表
  {
    path: "/futureService/shopOrderList",
    name: "shopOrderList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/integrateMall/shopOrderList"], resolve),
  },
  // 省级应用-积分商城------end
  // 签署领里公约页面
  {
    path: "/home/signNeighborhoodPact",
    name: "signNeighborhoodPact",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/other/signNeighborhoodPact"], resolve),
  },
  // 我的工单
  {
    path: "/futureService/myWorkOrder",
    name: "myWorkOrder",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/myWorkOrder/index"], resolve),
  },
  // 我的工单-人员列表（转派）
  {
    path: "/futureService/personnelTransfer",
    name: "personnelTransfer",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/myWorkOrder/personnelTransfer"], resolve),
  },
  // 我的工单-工单详情
  {
    path: "/futureService/workOrderDetail",
    name: "workOrderDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/myWorkOrder/workOrderDetail"], resolve),
  },
  // 报事报修
  {
    path: "/futureService/reportForRepair",
    name: "reportForRepair",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/reportForRepair/index"], resolve),
  },
  // 报事报修创建订单
  {
    path: "/futureService/reportForRepairCreate",
    name: "reportForRepairCreate",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/reportForRepair/reportForRepairCreate",
      ], resolve),
  },
  // 报事报修-提交成功
  {
    path: "/futureService/reportForRepairSuccess",
    name: "reportForRepairSuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/reportForRepair/reportForRepairSuccess",
      ], resolve),
  },
  // 报事报修-我的上报
  {
    path: "/futureService/reportForRepairReport",
    name: "reportForRepairReport",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/reportForRepair/reportForRepairReport",
      ], resolve),
  },
  // 报事报修详情
  {
    path: "/futureService/reportForRepairDetail",
    name: "reportForRepairDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/reportForRepair/reportForRepairDetail",
      ], resolve),
  },
  // 编辑提交住址
  {
    path: "/futureService/editPlace",
    name: "editPlace",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/clapCasually/editPlace"], resolve),
  },
  // 随手拍
  {
    path: "/futureService/clapCasually",
    name: "clapCasually",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/clapCasually/index"], resolve),
  },
  // 随手拍创建订单
  {
    path: "/futureService/clapCasuallyCreate",
    name: "clapCasuallyCreate",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/clapCasually/clapCasuallyCreate",
      ], resolve),
  },
  // 随手拍-提交成功
  {
    path: "/futureService/clapCasuallySuccess",
    name: "clapCasuallySuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/clapCasually/clapCasuallySuccess",
      ], resolve),
  },
  // 随手拍-我的上报
  {
    path: "/futureService/myReport",
    name: "myReport",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/clapCasually/myReport"], resolve),
  },
  // 随手拍详情
  {
    path: "/futureService/clapCasuallyDetail",
    name: "clapCasuallyDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/clapCasually/clapCasuallyDetail",
      ], resolve),
  },
  // 场地预约
  {
    path: "/futureService/areaAppoint",
    name: "areaAppoint",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/areaAppoint/index"], resolve),
  },
  // 场地详情
  {
    path: "/futureService/areaDetail",
    name: "areaDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/areaAppoint/areaDetail"], resolve),
  },
  // 场次预约
  {
    path: "/futureService/areaSession",
    name: "areaSession",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/areaAppoint/areaSession"], resolve),
  },
  // 预约成功
  {
    path: "/futureService/appointSuccess",
    name: "appointSuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/areaAppoint/appointSuccess"], resolve),
  },
  // 我的预约
  {
    path: "/futureService/appointList",
    name: "appointList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/areaAppoint/appointList"], resolve),
  },
  // 预约订单详情
  {
    path: "/futureService/appointDetail",
    name: "appointDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/areaAppoint/appointDetail"], resolve),
  },
  // 省级应用-场地预约---------start
  {
    path: "/futureService/venueAppoint",
    name: "venueAppoint",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/venueAppoint/index"], resolve),
  },
  // 场地详情
  {
    path: "/futureService/venueDetail",
    name: "venueDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/venueAppoint/venueDetail"], resolve),
  },
  // 场次预约
  {
    path: "/futureService/venueSession",
    name: "venueSession",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/venueAppoint/venueSession"], resolve),
  },
  // 预约成功
  {
    path: "/futureService/appointmentSuccess",
    name: "appointmentSuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/venueAppoint/appointmentSuccess",
      ], resolve),
  },
  // 我的预约
  {
    path: "/futureService/myAppointList",
    name: "myAppointList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/venueAppoint/myAppointList"], resolve),
  },
  // 预约订单详情
  {
    path: "/futureService/appointOrderDetail",
    name: "appointOrderDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/venueAppoint/appointOrderDetail",
      ], resolve),
  },
  // 省级应用-场地预约---------end
  // 明珠广播站列表
  {
    path: "/futureService/broadcastList",
    name: "broadcastList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/heBroadcast/broadcastList"], resolve),
  },
  // 明珠广播站
  {
    path: "/futureService/broadcastReplyDetail",
    name: "broadcastReplyDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/heBroadcast/broadcastReplyDetail",
      ], resolve),
  },
  //商家订单详情
  {
    path: "/futureService/verificationDetail",
    name: "verificationDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/verification/verificationDetail",
      ], resolve),
  },
  //商家订单列表
  {
    path: "/futureService/verificationList",
    name: "verificationList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/verification/verificationList"], resolve),
  },
  //问卷调查列表
  {
    path: "/futureService/questionnaireList",
    name: "questionnaireList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/questionnaire/questionnaireList.vue",
      ], resolve),
  },
  // 投票调查列表
  {
    path: "/futureService/voteList",
    name: "voteList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/vote/voteList.vue"], resolve),
  },
  // 身份认证
  {
    path: "/futureService/identityAuthentication",
    name: "identityAuthentication",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/vote/identityAuthentication.vue",
      ], resolve),
  },
  // //问卷详情
  // {
  //   path: "/futureService/questionnaire",
  //   name: "questionnaireAnswer",
  //   meta: {
  //     title: "",
  //   },
  //   component: (resolve) =>
  //     require([
  //       "@/views/futureService/questionnaire/questionnaireAnswer.vue",
  //     ], resolve),
  // },
  //明珠码
  {
    path: "/futureService/commonCode",
    name: "commonCode",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/commonCode/index.vue"], resolve),
  },
  // 身份识别
  {
    path: "/futureService/identifyQrCode",
    name: "identifyQrCode",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/identifyQrCode/index.vue"], resolve),
  },
  {
    path: "/futureService/identifyResult",
    name: "identifyResult",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/identifyQrCode/result.vue"], resolve),
  },
  //党建首页
  {
    path: "/futureService/partyBuilding",
    name: "partyBuilding",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/partyBuilding/home/index.vue"], resolve),
  },
  {
    path: "/futureService/partyTopic",
    name: "partyTopic",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/home/partyTopic.vue",
      ], resolve),
  },
  //亮相台
  {
    path: "/futureService/partyMember",
    name: "partyMember",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyMember/index.vue",
      ], resolve),
  },
  //党务通
  {
    path: "/futureService/partyAffairs",
    name: "partyAffairs",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/index.vue",
      ], resolve),
  },
  //三会一课
  {
    path: "/futureService/partyAffairsList",
    name: "partyAffairsList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/partyAffairsList.vue",
      ], resolve),
  },
  //党公告
  {
    path: "/futureService/partyNotice",
    name: "partyNotice",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/partyNotice.vue",
      ], resolve),
  },
  //主题党日
  {
    path: "/futureService/topicParty",
    name: "topicParty",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/topicParty.vue",
      ], resolve),
  },
  //党务通
  {
    path: "/futureService/partyAffairsDetail",
    name: "partyAffairsDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/partyAffairsDetail.vue",
      ], resolve),
  },
  //党员热议
  {
    path: "/futureService/rightsComment",
    name: "rightsComment",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/rightsComment.vue",
      ], resolve),
  },
  //课程表
  {
    path: "/futureService/classScheduleCard",
    name: "classScheduleCard",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/classScheduleCard.vue",
      ], resolve),
  },
  //师资详情
  {
    path: "/futureService/teacherDetail",
    name: "teacherDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/teacherDetail.vue",
      ], resolve),
  },
  //师资库
  {
    path: "/futureService/teacherLibrary",
    name: "teacherLibrary",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/teacherLibrary.vue",
      ], resolve),
  },
  //在线学习
  {
    path: "/futureService/onlineStudy",
    name: "onlineStudy",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/onlineStudy.vue",
      ], resolve),
  },
  {
    path: "/futureService/onLineStudyDetail",
    name: "onLineStudyDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/onLineStudyDetail.vue",
      ], resolve),
  },
  // 基层党校
  {
    path: "/futureService/grassRootsPartySchool",
    name: "grassRootsPartySchool",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/grassRootsPartySchool.vue",
      ], resolve),
  },
  //红管家
  {
    path: "/futureService/redHousekeeper",
    name: "redHousekeeper",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/redHousekeeper.vue",
      ], resolve),
  },
  //组织架构
  {
    path: "/futureService/partyOrg",
    name: "partyOrg",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/partyOrg.vue",
      ], resolve),
  },
  // //明珠托幼
  // {
  //   path: "/futureService/entrustChild",
  //   name: "entrustChild",
  //   meta: {
  //     title: "",
  //   },
  //   component: (resolve) =>
  //     require(["@/views/futureService/entrustChild/index.vue"], resolve),
  // },
  // //明珠托幼
  // {
  //   path: "/futureService/entrustChildList",
  //   name: "entrustChildList",
  //   meta: {
  //     title: "",
  //   },
  //   component: (resolve) =>
  //     require([
  //       "@/views/futureService/entrustChild/entrustChildList.vue",
  //     ], resolve),
  // },
  // //明珠托幼审核
  // {
  //   path: "/futureService/entrustManager",
  //   name: "entrustManager",
  //   meta: {
  //     title: "",
  //   },
  //   component: (resolve) =>
  //     require([
  //       "@/views/futureService/entrustChild/entrustManager.vue",
  //     ], resolve),
  // },
  // //我的预约
  // {
  //   path: "/futureService/myEntrustChild",
  //   name: "myEntrustChild",
  //   meta: {
  //     title: "",
  //   },
  //   component: (resolve) =>
  //     require([
  //       "@/views/futureService/entrustChild/myEntrustChild.vue",
  //     ], resolve),
  // },
  // // 明珠托幼视频
  // {
  //   path: "/futureService/entrustChildVideo",
  //   name: "entrustChildVideo",
  //   meta: {
  //     title: "",
  //   },
  //   component: (resolve) =>
  //     require([
  //       "@/views/futureService/entrustChild/entrustChildVideo.vue",
  //     ], resolve),
  // },
  // 商圈列表
  {
    path: "/futureService/businessDistrictList",
    name: "businessDistrictList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessCircle/businessDistrictList.vue",
      ], resolve),
  },
  // 修改商户地址
  {
    path: "/futureService/merchantAddressEdit",
    name: "merchantAddressEdit",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessCircle/merchantAddressEdit.vue",
      ], resolve),
  },
  // 商圈详情
  {
    path: "/futureService/businessDistrictDetail",
    name: "businessDistrictDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessCircle/businessDistrictDetail.vue",
      ], resolve),
  },
  //商户信息
  {
    path: "/futureService/businessCircle",
    name: "businessCircle",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/businessCircle/index.vue"], resolve),
  },
  //商圈冻结申诉
  {
    path: "/futureService/appeal",
    name: "appeal",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/businessCircle/appeal.vue"], resolve),
  },
  //商圈申请
  {
    path: "/futureService/businessCircleApply",
    name: "businessCircleApply",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessCircle/businessCircleApply.vue",
      ], resolve),
  },
  //商品订单
  {
    path: "/futureService/businessGood",
    name: "businessGood",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessCircle/businessGood.vue",
      ], resolve),
  },
  //订单详情
  {
    path: "/futureService/businessGoodDetail",
    name: "businessGoodDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessCircle/businessGoodDetail.vue",
      ], resolve),
  },
  //数字社区
  {
    path: "/futureService/digitalCommunity",
    name: "digitalCommunity",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/digitalCommunity/index.vue"], resolve),
  },
  //531三维工作法
  {
    path: "/futureService/threedWorkMethods",
    name: "threedWorkMethods",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/threedWorkMethods/index.vue"], resolve),
  },
  //531三维工作法-风采展示
  {
    path: "/futureService/styleExhibition",
    name: "styleExhibition",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/threedWorkMethods/styleExhibition.vue",
      ], resolve),
  },
  //531三维工作法-党建阵地
  {
    path: "/futureService/partyBuildingPosition",
    name: "partyBuildingPosition",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/threedWorkMethods/partyBuildingPosition.vue",
      ], resolve),
  },
  //531三维工作法-强党建
  {
    path: "/futureService/strengthenPartyBuilding",
    name: "strengthenPartyBuilding",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/threedWorkMethods/strengthenPartyBuilding.vue",
      ], resolve),
  },
  //531三维工作法-搭平台
  {
    path: "/futureService/buildPlatform",
    name: "buildPlatform",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/threedWorkMethods/buildPlatform.vue",
      ], resolve),
  },
  //531三维工作法-立制度
  {
    path: "/futureService/establishSsystem",
    name: "establishSsystem",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/threedWorkMethods/establishSsystem.vue",
      ], resolve),
  },
  //531三维工作法-建队伍
  {
    path: "/futureService/buildTeam",
    name: "buildTeam",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/threedWorkMethods/buildTeam.vue",
      ], resolve),
  },
  //531三维工作法-坚守初心
  {
    path: "/futureService/stickPrimacy",
    name: "stickPrimacy",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/threedWorkMethods/stickPrimacy.vue",
      ], resolve),
  },
  //531三维工作法-社区选举详情
  {
    path: "/futureService/communityElectionsDetail",
    name: "communityElectionsDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/threedWorkMethods/communityElectionsDetail.vue",
      ], resolve),
  },
  //智能充电桩
  {
    path: "/futureService/intelligentCharging",
    name: "intelligentCharging",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/intelligentCharging/index.vue"], resolve),
  },
  //日间照料预约首页
  {
    path: "/futureService/dayCareAppoint",
    name: "dayCareAppoint",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/dayCareAppoint/index"], resolve),
  },
  // 日间照料详情
  {
    path: "/futureService/dayCareDetail",
    name: "dayCareDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/dayCareAppoint/dayCareDetail"], resolve),
  },
  // 日间照料预约
  {
    path: "/futureService/dayCareSession",
    name: "dayCareSession",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/dayCareAppoint/dayCareSession"], resolve),
  },
  // 预约成功
  {
    path: "/futureService/dayCareAppointSuccess",
    name: "dayCareAppointSuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/dayCareAppoint/appointSuccess"], resolve),
  },
  // 我的预约
  {
    path: "/futureService/dayCareAppointList",
    name: "dayCareAppointList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/dayCareAppoint/appointList"], resolve),
  },
  // 预约订单详情
  {
    path: "/futureService/dayCareAppointDetail",
    name: "dayCareAppointDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/dayCareAppoint/appointDetail"], resolve),
  },
  // 远程开门
  {
    path: "/futureService/remoteDoorOpening",
    name: "remoteDoorOpening",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/remoteDoorOpening/index.vue"], resolve),
  },
  //管理后台令牌
  {
    path: "/futureService/administrativeToken",
    name: "administrativeToken",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/administrativeToken/index.vue"], resolve),
  },
  //人才服务
  {
    path: "/futureService/talentServices",
    name: "talentServices",
    meta: {
      title: "身份认证",
    },
    component: (resolve) =>
      require(["@/views/futureService/talentServices/index.vue"], resolve),
  },
  //人才服务-十项菜单
  {
    path: "/futureService/tenItemMenu",
    name: "tenItemMenu",
    meta: {
      title: "十项菜单",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/talentServices/tenItemMenu.vue",
      ], resolve),
  },
  //人才服务-人才认证
  {
    path: "/futureService/talentCertification",
    name: "talentCertification",
    meta: {
      title: "人才认证",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/talentServices/talentCertification.vue",
      ], resolve),
  },
  // 身份认证 - 人才认证
  {
    path: "/futureService/talentAuthentication",
    name: "talentAuthentication",
    meta: {
      title: "人才认证",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/talentServices/talentAuthentication.vue",
      ], resolve),
  },
  // 身份认证 - 工会会员认证
  {
    path: "/futureService/labourAuthentication",
    name: "labourAuthentication",
    meta: {
      title: "工会会员认证",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/talentServices/labourAuthentication.vue",
      ], resolve),
  },
  {
    path: "/futureService/laborUnionVip",
    name: "laborUnionVip",
    meta: {
      title: "工会会员认证",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/talentServices/laborUnionVip.vue",
      ], resolve),
  },
];
export default routes;
