const routes = [
  //教育地图
  {
    path: "/futureEdu/eduMap",
    name: "eduMap",
    meta: {
      title: "教育地图",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/eduMap/index"], resolve),
  },
  //明珠云校
  {
    path: "/futureEdu/cloudSchool",
    name: "cloudSchool",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/cloudSchool/index"], resolve),
  },
  //课程列表
  {
    path: "/futureEdu/courseList",
    name: "courseList",
    meta: {
      title: "",
      keepAlive: false,
      isUseCache: false,
    },
    component: (resolve) =>
      require(["@/views/futureEdu/cloudSchool/courseList"], resolve),
  },
  //课程详情
  {
    path: "/futureEdu/courseDetail",
    name: "courseDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/cloudSchool/courseDetail"], resolve),
  },
  /** 明珠托幼 */
  // 明珠托幼首页
  {
    path: "/futureEdu/entrustChild",
    name: "entrustChild",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/entrustChild/index.vue"], resolve),
  },
  //托管说明
  {
    path: "/futureEdu/entrustChildInfo",
    name: "entrustChildInfo",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/entrustChild/entrustChildInfo.vue"], resolve),
  },
  //明珠托幼
  {
    path: "/futureEdu/entrustChildList",
    name: "entrustChildList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/entrustChild/entrustChildList.vue"], resolve),
  },
  //明珠托幼审核
  {
    path: "/futureEdu/entrustManager",
    name: "entrustManager",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/entrustChild/entrustManager.vue"], resolve),
  },
  //我的预约
  {
    path: "/futureEdu/myEntrustChild",
    name: "myEntrustChild",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/entrustChild/myEntrustChild.vue"], resolve),
  },
  //明珠托幼视频
  {
    path: "/futureEdu/entrustChildVideo",
    name: "entrustChildVideo",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureEdu/entrustChild/entrustChildVideo.vue",
      ], resolve),
  },
  // 托幼签到
  {
    path: "/futureEdu/kindergartensSign",
    name: "kindergartensSign",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/kindergartensSign/index.vue"], resolve),
  },
  // 明珠夜校
  {
    path: "/futureEdu/nightSchool/allCourseList",
    name: "allCourseList",
    meta: {
      title: "所有课程",
    },
    component: (resolve) => require(["@/views/futureEdu/nightSchool/normalUser/allCourseList.vue"], resolve),
  },
  {
    path: "/futureEdu/nightSchool/allCourseDetail",
    name: "allCourseDetail",
    meta: {
      title: "课程详情",
    },
    component: (resolve) => require(["@/views/futureEdu/nightSchool/normalUser/allCourseDetail.vue"], resolve),
  },
  {
    path: "/futureEdu/nightSchool/signUpConfirm",
    name: "signUpConfirm",
    meta: {
      title: "确认报名",
    },
    component: (resolve) => require(["@/views/futureEdu/nightSchool/normalUser/signUpConfirm.vue"], resolve),
  },
  {
    path: "/futureEdu/nightSchool/myCourseSheetList",
    name: "myCourseSheetList",
    meta: {
      title: "我的课程",
    },
    component: (resolve) => require(["@/views/futureEdu/nightSchool/normalUser/myCourseSheetList.vue"], resolve),
  },
  {
    path: "/futureEdu/nightSchool/myCourseOrderList",
    name: "myCourseOrderList",
    meta: {
      title: "我的课程",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/nightSchool/normalUser/myCourseOrderList.vue"], resolve),
  },
  {
    path: "/futureEdu/nightSchool/myCourseDetail",
    name: "myCourseDetail",
    meta: {
      title: "课程详情",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/nightSchool/normalUser/myCourseSheetDetail.vue"], resolve),
  },
  {
    path: "/futureEdu/nightSchool/myCourseOrderDetail",
    name: "myCourseOrderDetail",
    meta: {
      title: "订单详情",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/nightSchool/normalUser/myCourseOrderDetail.vue"], resolve),
  },
  {
    path: "/futureEdu/nightSchool/courseManage",
    name: "nightSchoolCourseManage",
    meta: {
      title: "课程管理",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/nightSchool/manageUser/courseManage.vue"], resolve),
  },
  {
    path: "/futureEdu/nightSchool/courseSituation",
    name: "nightSchoolCourseSituation",
    meta: {
      title: "课程详情",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/nightSchool/manageUser/courseSituation.vue"], resolve),
  },
  {
    path: "/futureEdu/nightSchool/signUpSituation",
    name: "nightSchoolSignUpSituation",
    meta: {
      title: "课程详情",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/nightSchool/manageUser/signUpSituation.vue"], resolve),
  },
  {
    path: "/futureEdu/nightSchool/signUpDetail",
    name: "nightSchoolSignUpDetail",
    meta: {
      title: "报名详情",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/nightSchool/manageUser/signUpDetail.vue"], resolve),
  },
  /** end */
];

export default routes;
