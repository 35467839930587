const routes = [
  //  第三方二维码中转页面
  {
    path: "/home/blank",
    name: "blank",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/other/blank"], resolve),
  },
  //  临时通知
  {
    path: "/home/tempNotice",
    name: "tempNotice",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/other/tempNotice"], resolve),
  },
  //  联系明珠
  {
    path: "/home/notice",
    name: "notice",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/other/notice"], resolve),
  },
  //  明珠广播站
  {
    path: "/home/introduce",
    name: "introduce",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/other/introduce"], resolve),
  },
  {
    path: "/home/manual",
    name: "manual",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/other/manual"], resolve),
  },
  {
    path: "/home/treaty",
    name: "treaty",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/other/treaty"], resolve),
  },
  {
    path: "/home/thirdTest",
    name: "thirdTest",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/other/thirdTest"], resolve),
  },
  {
    path: "/home/login",
    name: "login",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/other/login"], resolve),
  },
  //我的意见
  {
    path: "/home/myAdvice",
    name: "myAdvice",
    meta: {
      title: "我的意见",
    },
    component: (resolve) => require(["@/views/other/myAdvice"], resolve),
  }, //意见反馈
  {
    path: "/home/myAdviceForm",
    name: "myAdviceForm",
    meta: {
      title: "意见反馈",
    },
    component: (resolve) => require(["@/views/other/myAdviceForm"], resolve),
  },
];

export default routes;
